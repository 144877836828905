import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "chatRoom" */ '../views/auth.vue')
  },
  {
    path: '/chatRoom',
    name: 'chatRoom',
    component: () => import(/* webpackChunkName: "chatRoom" */ '../views/chatRoom.vue')
  },
  {
    path: '/sharing/:id',
    name: 'sharing',
    component: () => import(/* webpackChunkName: "sharing" */ '../views/sharing.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
